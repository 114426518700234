import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorHandler, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatNativeDateModule} from '@angular/material/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {LocalSharedModule} from './shared/localShared.module';
import {AppRoutingModule} from './app-routing.module';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {AppComponent} from './app.component';
import {CookieService} from 'ngx-cookie-service';
import {CommonModule, DatePipe} from '@angular/common';
import {environment} from '../environments/environment';
import {UnsubscribeService} from '@libs/shared/services/unsubscribe.service';
import {HttpErrorInterceptor} from '@libs/interceptors/http-error-interceptor';
import {TokenInterceptor} from '@libs/interceptors/token.interceptor';
import {UrlService} from '@libs/services/url.service';
import {CardService} from '@libs/services/card.service';
import {LoanService} from '@libs/services/loan.service';
import {ClientService} from '@libs/services/client.service';
import {AuthGuard} from '@libs/guards/auth.guard';
import {ApplicationGuard} from '@libs/guards/application.guard';
import {FormGuard} from '@libs/guards/form.guard';
import {StageGuard} from '@libs/guards/stage.guard';
import {IdentGuard} from '@libs/guards/ident.guard';
import {ClientStore} from '@libs/shared/storage/client.store';
import {LastApplicationStorage} from '@libs/shared/storage/last-application.storage';
import {LanguageStore} from '@libs/shared/storage/language.store';
import {ProductStore} from '@libs/shared/storage/product.store';
import {UserVerificationService} from '@libs/services/user-verification.service';
import {ProductService} from '@libs/services/product.service';
import {ScannerService} from '@libs/services/scanner.service';
import {DocumentService} from '@libs/services/document.service';
import {GuidService} from '@libs/shared/services/guid.service';
import {PrivateModeService} from '@libs/shared/services/private-mode.service';
import {AffiliateService} from '@libs/shared/services/affiliate.service';
import {LoansStorage} from '@libs/shared/storage/loans.storage';
import {FormExecutorService} from '@libs/services/form-executor.service';
import {BankIdService} from '@libs/services/bank-id.service';
import {SharedModule} from '@libs/shared/shared.module';
import {BankIdMainModule} from '@libs/modules/bankid/bank-id-main.module';
import {FormModule} from '@libs/modules/form/form.module';
import {LoaderModule} from '@libs/shared/components/loader/loader.module';
import {CalculateStore} from '@libs/shared/storage/calculate.store';
import {PRODUCT_CONFIG, PRODUCT_CONFIG_TOKEN} from '@libs/configs/calculate-config';
import {WINDOW} from '@libs/shared/injections/window';
import {GlobalErrorHandler} from '@libs/interceptors/global-error-handler';
import {HeaderModule} from '@libs/modules/header/header.module';
import {AuthModule} from '@libs/modules/auth/auth.module';
import {CommunicationModule} from '@libs/shared/components/communication/communication.module';
import {ToasterWidgetModule} from '@libs/shared/components/toaster-widget/toaster-widget.module';

const PROVIDERS = [
  {provide: 'environment', useValue: environment},
  {provide: WINDOW, useValue: window},
  {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true,},
  {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true,},
  {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaKEY_CODE,},
  {provide: PRODUCT_CONFIG_TOKEN, useValue: PRODUCT_CONFIG},
  {provide: ErrorHandler, useClass: GlobalErrorHandler},
];

const STORAGES = [
  ClientStore,
  LastApplicationStorage,
  LanguageStore,
  ProductStore,
  LoansStorage,
  CalculateStore
];

const GUARDS = [
  AuthGuard,
  ApplicationGuard,
  FormGuard,
  StageGuard,
  IdentGuard,
];


@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    AuthModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    DeviceDetectorModule.forRoot(),
    RecaptchaV3Module,
    BankIdMainModule,
    FormModule,
    LocalSharedModule,
    SharedModule,
    LoaderModule,
    HeaderModule,
    CommunicationModule,
    ToasterWidgetModule
  ],
  providers: [
    ...PROVIDERS,
    ...GUARDS,
    ...STORAGES,
    UrlService,
    CardService,
    LoanService,
    BsModalService,
    ClientService,
    UserVerificationService,
    ProductService,
    ScannerService,
    CookieService,
    DocumentService,
    GuidService,
    PrivateModeService,
    AffiliateService,
    DatePipe,
    LoansStorage,
    UnsubscribeService,
    FormExecutorService,
    BankIdService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
