export const environment = {
  production: true,
  name: 'ZECREDIT',
  group: 'BASE',
  hostUrl: `https://back.zecredit.com.ua/v1/api/rest`,
  isTestPaymentEnabled: false,
  isInnValidationEnabled: true,
  recaptchaKEY_CODE: '6LdlsMcZAAAAAONUnY12cN35M-h4yesCoEkzXuUC',
  cookieDomain: '.zecredit.com.ua',
  WEBSITE_URL: 'https://zecredit.com.ua/',
  LOGO: '../assets/images/logo.png',
  CONTACT: 'tel:+30800352706',
  partnerLink:
    'http://groshi.bez-otkazov.com/?utm_source=ze&utm_medium=refferal&utm_campaign=lk&utm_term=',
  otherCompanyRedirect_cab:
    'utm_source=refferal&utm_medium=zecredit&utm_campaign=link_on_a_button_main_page',
  otherCompanyRedirect_form:
    'utm_source=refferal&utm_medium=zec-zayavka-bk&utm_campaign=redirect',
};
