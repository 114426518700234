import * as Models from '../../../../../libs/models/models';

export class ContactConfig {

  static phones: Array<Models.IContact> = [
    {
      label: '0800 352 706',
      contact: 'tel:0800352706',
    },
    {
      label: '+38063 25 04 060',
      contact: 'tel:+380632504060',
    }
  ];

  static emails: Array<Models.IContact> = [
    {
      label: 'mailto:info@zecredit.com.ua',
      contact: 'info@zecredit.com.ua'
    }
  ];
}
