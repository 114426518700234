<footer class="footer">
  <div class="footer__wrap container">
    <div class="row">
      <div class="col-12 col-md-5 col-lg-5" *ngIf="router.url === '/auth/login'" [innerHtml]="info[lang]"></div>
      <div class="col-12 col-md-6 col-lg-6">
        <ul class="contact">
          <li class="contact__block">
            <a class="contact__link" *ngFor="let item of phones" [href]="[item.contact]"><img src="/assets/img/phone.svg" width="20"
                                                                   alt=""> {{ item.label }}</a>
          </li>
          <li class="contact__block">
            <a class="contact__link" href="mailto:info@zecredit.com.ua"><img src="/assets/img/mail.svg" width="20"
                                                                             alt=""> info@zecredit.com.ua</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
